import { DefaultUniversalButton } from '@fe/components/UniversalButton'
import styled, { css } from '@fe/styles/styledComponents'

export interface IButton {
  disabled?: boolean
  isBlock?: boolean
  isCentered?: boolean
  isLarge?: boolean
  isTopMargin?: boolean
}

const base = css<IButton>`
  cursor: pointer;
  outline: none;
  border: none;
  font-family: ${(p) => p.theme.font.allplants};
  text-transform: uppercase;
  font-weight: 700;
  transition: ${(p) => p.theme.transition.default};
  &:hover {
    background: black;
    color: white;
  }
  ${(p) =>
    p.disabled &&
    css`
      background-color: red;
      color: white;
    `}
  ${(p) =>
    p.isBlock &&
    css`
      width: 100%;
      text-align: center;
    `}
  line-height: 18px;
  font-size: 18px;
  ${(p) =>
    p.isLarge &&
    css`
      line-height: 22px;
      font-size: 22px;
    `};
  ${(p) =>
    p.isCentered &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}
  ${(p) =>
    p.isTopMargin &&
    css`
      margin-top: 6px;
    `};
`

export const Blank = styled(DefaultUniversalButton)`
  cursor: pointer;
  border: none;
  background: transparent;
  color: inherit;
  text-decoration: none;
  font-size: inherit;
  text-align: center;
`

export interface IVariant {
  variant?: 'primary' | 'secondary'
}
const ButtonColorVariant = css<IButton & IVariant>`
  ${(p) => {
    switch (p.variant) {
      default:
      case 'primary':
        return css<IButton>`
          background-color: ${p.theme.color.button.active};
          color: black;
          ${p.disabled &&
          css`
            background-color: ${p.theme.color.button.disabled};
            color: white;
          `}
        `
      case 'secondary':
        return css<IButton>`
          background-color: #000;
          color: #fff;
          &:hover {
            background-color: ${p.theme.color.button.active};
            color: black;
          }
          ${p.disabled &&
          css`
            color: rgba(0, 0, 0, 0.6);
          `}
        `
    }
  }}
`

export const Input = styled(DefaultUniversalButton)<IButton>`
  ${base}
  margin-bottom: 12px;
  padding: 4px 12px;
  font-size: 18px;
  background-color: ${(p) => p.theme.color.button.active};
  color: black;
  ${ButtonColorVariant}
  ${(p) =>
    p.disabled &&
    css`
      background-color: ${p.theme.color.button.disabled};
      color: black;
      pointer-events: none;
    `}
`
export const Basic = styled(DefaultUniversalButton)<
  IButton &
    IVariant & {
      isCondensed?: boolean
      isBlock?: boolean
    }
>`
  ${base}
  text-align: center;
  display: inline-block;
  margin-bottom: 12px;
  letter-spacing: 1px;
  text-decoration: none;
  padding: ${(p) => (p.isCondensed ? '12px 20px' : '12px 40px')};
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `}
  ${(p) => p.theme.media.tablet`
    line-height: 28px;
    padding: ${p.isCondensed ? '12px 20px' : '16px 80px'};
  `}
  &:hover {
    background-color: #000;
    color: #fff;
  }
  ${ButtonColorVariant}
  ${(p) =>
    p.isBlock
      ? css`
          width: 100%;
        `
      : css`
          ${!p.isCondensed &&
          css`
            min-width: 200px;
          `}
          ${p.theme.media.tablet`
      ${
        !p.isCondensed &&
        css`
          min-width: 300px;
        `
      }
    `}
        `}
`

export const PaymentButton = styled(DefaultUniversalButton)<IButton>`
  ${base}
  width: 120px;
  padding: 9px 28px;
  background-color: ${(p) => p.theme.color.button.active};
`

export const FormButton = styled(DefaultUniversalButton)<IButton & IVariant>`
  ${base}
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 12px;
  min-width: 200px;
  letter-spacing: 1px;
  padding: 12px 40px;
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `}
  ${ButtonColorVariant}
`

export const OutlineButton = styled(Blank)<IButton>`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  min-width: 72px;
  align-self: baseline;
  justify-content: center;
  border: 2px solid ${(p) => p.theme.color.sunrise};
  color: #000000;
  letter-spacing: 0.5px;
  padding: 4px 8px;
  margin: 0 auto;
  transition: ${(p) => p.theme.transition.default};
  ${(p) =>
    p.isTopMargin &&
    css`
      margin-top: 6px;
    `};
  background-color: ${(p) => p.theme.color.page};
  &:hover {
    border-color: black;
    background-color: black;
    color: white;
  }
`

export const TextButton = styled(DefaultUniversalButton)<IButton>`
  ${base}
  background: transparent;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  ${(p) => p.theme.media.tablet`
    font-size: 16px;
    line-height: 28px;
  `}
  ${(p) =>
    p.isNotDecorated &&
    css`
      text-decoration: none;
    `};
  &:hover {
    opacity: 1;
  }
`
export const Text = styled(Blank)<{
  color?: string
  isNotDecorated?: boolean
  disableColoring?: boolean
  isBold?: boolean
  isItalic?: boolean
}>`
  cursor: pointer;
  text-decoration: underline;
  font-size: inherit;
  padding: 0;
  color: ${(p) => (p.disableColoring ? 'inherit' : p.theme.color.link.default)};
  ${(p) =>
    p.isNotDecorated &&
    css`
      text-decoration: none;
    `};

  ${(p) =>
    p.isItalic &&
    css`
      font-style: italic;
    `};

  ${(p) =>
    p.isBold &&
    css`
      font-weight: bold;
    `};
`
export const Inline = styled(Blank)`
  cursor: pointer;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
`

export default Basic
