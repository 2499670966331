/* eslint-disable camelcase */

export enum DiscountCalculationType {
  Percentage = 'PERCENTAGE',
  FixedAmount = 'FIXED_AMOUNT',
}
export enum CartStatus {
  Initial = 'INITIAL',
  Editing = 'EDITING',
  Checkout = 'CHECKOUT',
  Completed = 'COMPLETED',
  Purchasable = 'PURCHASABLE',
}
export enum TransactionStatus {
  Success = 'SUCCESS',
  Error = 'ERROR',
  Queued = 'QUEUED',
  Skipped = 'SKIPPED',
  Cancelled = 'CANCELLED',
}

export enum TransactionType {
  Charge = 'CHARGE',
  RecurringCharge = 'RECURRING_CHARGE',
  Refund = 'REFUND',
}
export enum UserOrderType {
  Acquisition = 'ACQUISITION',
  Reorder = 'REORDER',
}

export enum PortionSize {
  SinglePortion = 'SINGLE_PORTION',
  DoublePortion = 'DOUBLE_PORTION',
}

export enum OrderStatus {
  PendingCharge = 'PENDING_CHARGE',
  PendingFulfilment = 'PENDING_FULFILMENT',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  ChargeFailed = 'CHARGE_FAILED',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Refunded = 'REFUNDED',
}

export enum OrderType {
  Shopify = 'SHOPIFY',
  ShopifyManual = 'SHOPIFY_MANUAL',
  RechargeCheckout = 'RECHARGE_CHECKOUT',
  Recharge = 'RECHARGE',
  Allplants = 'ALLPLANTS',
  GiftCard = 'GIFT_CARD',
  Bold = 'BOLD',
}

export enum StockStatus {
  Unknown = 'UNKNOWN',
  SoldOut = 'SOLD_OUT',
  Low = 'LOW',
  Fine = 'FINE',
}
export enum OrderFrequency {
  OneWeek = 'ONE_WEEK',
  TwoWeeks = 'TWO_WEEKS',
  ThreeWeeks = 'THREE_WEEKS',
  FourWeeks = 'FOUR_WEEKS',
  FiveWeeks = 'FIVE_WEEKS',
  SixWeeks = 'SIX_WEEKS',
  SevenWeeks = 'SEVEN_WEEKS',
  EightWeeks = 'EIGHT_WEEKS',
}

export enum DiscountFrequencyType {
  Subscription = 'SUBSCRIPTION',
  OneTime = 'ONE_TIME',
}

export type SubscriptionType = 'RECURRING' | 'ONE_TIME'

export type ProductType =
  | 'MEAL'
  | 'TREAT'
  | 'SMOOTHIE'
  | 'PIZZA'
  | 'DISH'
  | 'BUNDLE'
  | 'GIFT_CARD'
  | 'DELIVERY'
  | 'ADDON'
  | 'BREAKFAST'
  | 'SIDE'
  | 'MARKETING'

export type Maybe<T> = T | null

export interface IResponse<T> {
  data?: Array<T>
  errors: [any]
  status: number
}
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
}

export type OrderResult = {
  readonly id: Scalars['Int']
  readonly createdAt: Scalars['String']
  readonly status: OrderStatus
  readonly gateway: Scalars['String']
  readonly amount?: Maybe<Scalars['Int']>
  readonly transactionStatus: TransactionStatus
  readonly transactionType: TransactionType
  readonly customerId: Scalars['Int']
  readonly shopifyId: Scalars['Int']
  readonly shopifyOrderName: Scalars['String']
  readonly userOrderType: UserOrderType
}

export type PaymentSecretResult = {
  readonly orderId: Scalars['String']
  readonly paymentIntentSecret: Scalars['String']
  readonly userOrderType: UserOrderType
}

export type OrderInput = {
  readonly email: Scalars['String']
  readonly firstName: Scalars['String']
  readonly lastName: Scalars['String']
  readonly password?: Maybe<Scalars['String']>
  readonly acceptsMarketing: Scalars['Boolean']
  readonly acceptsMarketingSms: Scalars['Boolean']
  readonly acceptsMarketingPost: Scalars['Boolean']
  readonly acceptsThirdParty: Scalars['Boolean']
  readonly phone?: Maybe<Scalars['String']>
  readonly note?: Maybe<Scalars['String']>
  readonly paymentMethodId?: Maybe<Scalars['String']>
  readonly cartJson: Scalars['String']
  readonly cartToken: Scalars['String']
  readonly totalPrice: Scalars['Int']
  readonly totalDiscount: Scalars['Int']
  readonly portionSize?: Maybe<PortionSize>
  readonly discountCodes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly utm?: Maybe<Scalars['String']>
  readonly frequency?: Maybe<OrderFrequency>
  readonly deliveryAt?: Maybe<Scalars['String']>
  readonly deliveryFirstName?: Maybe<Scalars['String']>
  readonly deliveryLastName?: Maybe<Scalars['String']>
  readonly address1?: Maybe<Scalars['String']>
  readonly address2?: Maybe<Scalars['String']>
  readonly city?: Maybe<Scalars['String']>
  readonly postalCode?: Maybe<Scalars['String']>
  readonly province?: Maybe<Scalars['String']>
  readonly billingAddress1: Scalars['String']
  readonly billingAddress2?: Maybe<Scalars['String']>
  readonly billingCity: Scalars['String']
  readonly billingPostalCode: Scalars['String']
  readonly billingCountry?: Maybe<Scalars['String']>
  readonly billingProvince?: Maybe<Scalars['String']>
  readonly billingPhone?: Maybe<Scalars['String']>
}

export type ProductImage = {
  id: string
  product_id: string
  position: number
  // created_at: 2018-11-16T07:09:51-05:00,
  // updated_at: 2018-11-16T07:09:51-05:00,
  alt: string
  width: number
  height: number
  src: string
  // variant_ids: []
}
export type ProductVariant = {
  id: number
  created_at: string
  updated_at: string
  shopify_id: number
  sku_code: string
  title: string
  price: number
  variant_type: 'ONE_TIME' | 'SUBSCRIPTION'
  portion_size: 'SINGLE_PORTION' | 'DOUBLE_PORTION'
  stock_status: 'AVAILABLE' | 'LOW' | 'SOLD_OUT'
  type: string
  link: string
}
export type Product = {
  id: string
  created_at: string
  updated_at: string
  shopify_id: number
  title: string
  handle: string
  html_body: string
  image_url: string
  images: Array<ProductImage>
  tags: Array<string>
  variants: Array<ProductVariant>
  sku_code: string
  // published_at: 2019-06-20T17:07:53.774108,
  // product_type: DISH,
  // status: PUBLISHED,
}

export type UnsupportedPostcodeContactInput = {
  readonly name: Scalars['String']
  readonly email: Scalars['String']
  readonly postcode: Scalars['String']
  readonly keepInTouch: Scalars['Boolean']
  readonly newsletter: Scalars['Boolean']
}

export type Discount = {
  readonly code: Scalars['String']
  readonly amount: Scalars['Float']
  readonly discountType: DiscountCalculationType
}

export type DiscountInput = {
  readonly code: Scalars['String']
}

export type CartItem = {
  readonly id?: Maybe<Scalars['String']>
  readonly coreProductId?: Maybe<Scalars['String']>
  readonly skuCode?: Maybe<Scalars['String']>
  readonly imageUrl?: Maybe<Scalars['String']>
  readonly productDescription?: Maybe<Scalars['String']>
  readonly productId?: Maybe<Scalars['Int']>
  readonly productTitle?: Maybe<Scalars['String']>
  readonly url?: Maybe<Scalars['String']>
  readonly quantity?: Maybe<Scalars['Int']>
  readonly title?: Maybe<Scalars['String']>
  readonly price?: Maybe<Scalars['Int']>
  readonly firstAvailableDate?: Maybe<Scalars['String']>
  readonly stockStatus: StockStatus
  readonly productType: Scalars['String']
  readonly variantId: Maybe<Scalars['String']>
  readonly variantTitle: Maybe<Scalars['String']>
}

export type Cart = {
  readonly id: Scalars['Int']
  readonly token?: Maybe<Scalars['String']>
  readonly status?: Maybe<CartStatus>
  readonly note?: Maybe<Scalars['String']>
  readonly subtotalPrice?: number
  readonly totalPrice?: Maybe<Scalars['Int']>
  readonly totalDiscount?: Maybe<Scalars['Int']>
  readonly originalTotalPrice?: Maybe<Scalars['Int']>
  readonly shippingFrequency: Scalars['Int']
  readonly shippingPrice?: Maybe<Scalars['Int']>
  readonly portionSize?: Maybe<PortionSize>
  readonly requiresShipping: Scalars['Boolean']
  readonly hasGiftCard: Scalars['Boolean']
  readonly deliveryDate?: Maybe<Scalars['String']>
  readonly itemCount?: Maybe<Scalars['Int']>
  readonly skus?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly subscriptionType?: SubscriptionType
  readonly raw?: Maybe<Scalars['String']>
  readonly items: ReadonlyArray<CartItem>
  readonly discountCodes?: Maybe<ReadonlyArray<Discount>>
}

export type CartUpdateRequest = {
  readonly frequency?: Maybe<OrderFrequency>
  readonly status?: Maybe<CartStatus>
  readonly portion_size?: Maybe<PortionSize>
  readonly discount_codes?: Maybe<ReadonlyArray<DiscountInput>>
}

export type OrderItem = {
  readonly product_id?: Maybe<Scalars['Int']>
  readonly sku_code?: Maybe<Scalars['String']>
  readonly product_title?: Maybe<Scalars['String']>
  readonly quantity?: Maybe<Scalars['Int']>
  readonly product_type?: Maybe<Scalars['String']>
}

export type CheckoutOrder = {
  readonly email: Scalars['String']
  readonly first_name: Scalars['String']
  readonly last_name: Scalars['String']
  readonly shopify_order_name?: Maybe<Scalars['String']>
  readonly total_price?: Maybe<Scalars['Int']>
  readonly deliver_at?: Maybe<Scalars['String']>
  readonly delivery_day?: Maybe<Scalars['String']>
  readonly portion_size?: Maybe<PortionSize>
  readonly is_first_order?: Maybe<Scalars['Boolean']>
  readonly is_subscription?: Maybe<Scalars['Boolean']>
  readonly items?: Maybe<ReadonlyArray<Maybe<OrderItem>>>
}

export type DeliveryOptions = {
  readonly skus?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly excludedDates?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly firstDate?: Maybe<Scalars['String']>
  readonly min?: Maybe<Scalars['String']>
  readonly max?: Maybe<Scalars['String']>
}

export type GetAddressAddress = {
  readonly line1?: Maybe<Scalars['String']>
  readonly line2?: Maybe<Scalars['String']>
  readonly line3?: Maybe<Scalars['String']>
  readonly line4?: Maybe<Scalars['String']>
  readonly locality?: Maybe<Scalars['String']>
  readonly town?: Maybe<Scalars['String']>
  readonly county?: Maybe<Scalars['String']>
}

export type GetAddressResponse = {
  readonly addresses?: Maybe<ReadonlyArray<Maybe<GetAddressAddress>>>
}

export type Address = {
  firstName: string
  lastName: string
  line1: string
  line2?: string
  town: string
  county?: string
  postcode: string
}
