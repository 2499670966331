/* eslint-disable @typescript-eslint/no-unused-vars */
import 'airbnb-js-shims'
import 'react-dates/initialize'
import * as React from 'react'

import { connect, Field, FieldProps } from 'formik'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates'

import * as e from '@fe/components/Elements'
import withSizes, { ISizes } from '@fe/components/common/Size'
import { trackSelect } from '@fe/services/analytics'
import styled from '@fe/styles'

import * as c from './date.components'
import InputWrapper from './inputWrapper'

const CalendarIcon = styled(e.Icon.Calendar)`
  position: relative;
  z-index: ${(p) => p.theme.depth.foreground};
`
interface IDatePicker {
  isDayBlocked: (date: moment.Moment) => boolean
  isDayHighlighted: (date: moment.Moment) => boolean
  onDateChange: (date: moment.Moment | null) => void
}

class DatePicker extends InputWrapper<
  {
    sizes: ISizes
    dateFormat?: string
    invalidDateMessage?: string
  } & IDatePicker,
  {
    isFocused: boolean
  }
> {
  public static defaultProps = {
    ...InputWrapper.defaultProps,
    onDateChange: () => null,
    invalidDateMessage: 'please select a valid date',
  }

  private inputRef = React.createRef<HTMLElement>()

  protected manuallySetFocusedAndDirty = true

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      isFocused: false,
    }
  }

  protected validate = (value): string | undefined => {
    const { isDayBlocked, invalidDateMessage } = this.props
    if (value) {
      const date = moment(value)
      if (!date.isValid() || isDayBlocked(date)) {
        return invalidDateMessage
      }
    }
  }

  protected renderInput = () => {
    const { isFocused } = this.state
    const {
      label,
      iconEnd,
      onDateChange,
      isDayBlocked,
      isDayHighlighted,
      sizes,
      formik,
      dateFormat,

      // Remove from rest
      invalidDateMessage,
      name,
      onChange,
      defaultErrorMessage,
      ...rest
    } = this.props

    const isTabletOrSmaller = !sizes.greaterThan.tablet

    return (
      <>
        <c.DatePickerGlobalStyles />
        <c.AllplantsStyling
          ref={this.inputRef}
          isDirty={this.isDirty}
          isFocused={this.isFocused}
          isShowingLabel={!this.isEmpty}
          isValid={this.isValid}
        >
          <Field name={this.name} validate={this.validate}>
            {(formikProps: FieldProps) => (
              <SingleDatePicker
                calendarInfoPosition={isTabletOrSmaller ? 'top' : 'bottom'}
                customInputIcon={<CalendarIcon />}
                disableScroll={isTabletOrSmaller ? isFocused : undefined}
                displayFormat={dateFormat || 'dddd Do MMMM YYYY'}
                firstDayOfWeek={1}
                focused={isFocused}
                hideKeyboardShortcutsPanel
                id={this.name}
                inputIconPosition='after'
                isDayBlocked={isDayBlocked}
                isDayHighlighted={isDayHighlighted}
                navNext={
                  isTabletOrSmaller ? undefined : <c.Arrow direction='right' />
                }
                // Switch on tablet
                navPrev={
                  isTabletOrSmaller ? undefined : <c.Arrow direction='left' />
                }
                onClose={() => {
                  if (isFocused) this.setState({ isFocused: false })
                  formik.setFieldTouched(this.name, true)
                  if (isTabletOrSmaller && this.inputRef.current) {
                    this.inputRef.current!.scrollIntoView()
                  }
                }}
                onFocusChange={({ focused }) => {
                  if (focused !== isFocused)
                    this.setState({ isFocused: !!focused })
                  if (!focused) formik.setFieldTouched(this.name, true)
                }}
                orientation={isTabletOrSmaller ? 'vertical' : 'horizontal'}
                readOnly
                // End switch
                withFullScreenPortal={isTabletOrSmaller}
                {...rest}
                date={
                  formikProps.field.value
                    ? moment(formikProps.field.value)
                    : null
                }
                onDateChange={(date) => {
                  onDateChange!(date)
                  formik.setFieldValue(
                    this.name,
                    date ? date.toISOString() : ''
                  )
                  const dateInfo = date
                    ? {
                        date: date.format('DD-MM-YYYY'),
                        day: date.format('dddd'),
                        dayOfYear: date.format('DDDD'),
                      }
                    : {
                        date: '',
                        day: '',
                        dayOfYear: '',
                      }
                  void trackSelect('date', this.name, dateInfo)
                }}
              />
            )}
          </Field>
        </c.AllplantsStyling>
      </>
    )
  }
}

export default withSizes()(connect(DatePicker))
