import axios from 'axios'
import camelcase from 'camelcase-keys'

import config from '@fe/config'
import logger from '@fe/services/sentry'
import {
  IResponse,
  Product,
  CheckoutOrder,
  DeliveryOptions,
} from '@fe/types/allplants'
import { IUnsupportedPostcodeInterestRequest } from '@fe/types/api'
import { uuid } from '@fe/utils/helpers'

const allplantsApiAuth = axios.create({
  baseURL: config.coreDomain,
  withCredentials: true,
})
allplantsApiAuth.defaults.headers.common['X-CSRF-TOKEN'] = uuid

const allplantsApi = axios.create({
  baseURL: config.coreDomain,
})
allplantsApi.defaults.headers.common['X-CSRF-TOKEN'] = uuid

const getCustomersMe = async () => {
  // eslint-disable-next-line no-useless-catch
  try {
    const userDetails = await allplantsApiAuth.get('customers/me')
    return {
      firstName: userDetails?.data.data[0]?.first_name,
      lastName: userDetails?.data.data[0]?.last_name,
      email: userDetails?.data.data[0]?.email,
    }
  } catch (e) {
    logger('Failed fetch the customer', e)
    throw e
  }
}

const getCart = async (token: string) => {
  try {
    return await allplantsApiAuth.get<IResponse<any>>(`/carts/${token}`)
  } catch (e) {
    logger(`Failed to get the cart ${token}`)
    throw e
  }
}
const getProducts = async () => {
  try {
    return await allplantsApi.get<IResponse<Product>>('/products')
  } catch (e) {
    logger('Failed to get the products')
    throw e
  }
}
const updateCart = async (token: string, changes: any) => {
  try {
    return await allplantsApiAuth.put<IResponse<any>>(
      `/carts/${token}`,
      changes
    )
  } catch (e) {
    const allplantsError = e.response.data?.errors?.[0]
    if (allplantsError?.code !== 400) logger('Failed to update the cart')
    throw e
  }
}
const createOrder = async (order) => axios.post('/api/order', order)

const sendPayment = async ({ payment_intent_id, order_id, userOrderType }) =>
  axios.put('/api/payment', { payment_intent_id, order_id, userOrderType })

const getDeliveryDates = async (skus) => {
  try {
    const skuString = skus.join(',')
    const response = await allplantsApi.get<IResponse<DeliveryOptions>>(
      '/delivery_days',
      {
        params: {
          skus: skuString,
          check_second_shipping_limit: true,
        },
      }
    )
    const data = camelcase(response.data as any, { deep: true }) as any
    data.skus = skuString
    return data
  } catch (e) {
    logger(`Failed to get delivery dates for ${skus}`)
    throw e
  }
}
const registerUnsupportedPostcodeInterest = async (
  user: IUnsupportedPostcodeInterestRequest
) => {
  try {
    return await axios.post('/api/register-unsupported-postcode-interest', user)
  } catch (e) {
    logger(
      `Failed to register unsupported postcode for ${
        user.name
      }: ${JSON.stringify(user)}`
    )
  }
}
const getCheckoutOrder = (token: string) =>
  allplantsApi.get<IResponse<CheckoutOrder>>(`/orders/checkout/${token}`)

export {
  updateCart,
  createOrder,
  getDeliveryDates,
  getCustomersMe,
  registerUnsupportedPostcodeInterest,
  getCheckoutOrder,
  getCart,
  getProducts,
  sendPayment,
}
